@import "./styles/base/universal";
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,300;0,6..12,400;0,6..12,500;0,6..12,600;0,6..12,700;1,6..12,200;1,6..12,300;1,6..12,400;1,6..12,600;1,6..12,700&display=swap");

body {
  margin: 0;
  font-family: "Nunito Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1e1e1e;
  font-size: $font-small;
  background-color: #fff;
  line-height: normal;
}

* {
  font-family: "Nunito Sans", sans-serif !important;
}

code {}

.custonInput {
  border: solid 1px #54565b;
  font-size: 12px;
  border-radius: 2px;
  min-height: 40px;
  color: #222222;
}

.customSelect {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.customLabel {
  position: absolute;
  font-size: 11px;
  top: -10px;
  background-color: #fff;
  left: 10px;
  color: #54565b;
}

.alert-text {
  font-size: 12px !important;
}

.innerLogo {
  font-size: 62px;
  color: #1c75bb;

  span {
    display: block;
    font-size: 14px;
    color: #222222;
    font-weight: normal;
  }
}

.btn-link {
  color: #1c75bb;
  font-size: 12px;
  font-weight: bold;
}

.form-control.is-invalid {
  background: transparent;
}

.btn-primary {
  min-width: 155px;
  font-size: 12px;
  color: #fff;
  min-height: 40px;
  border-radius: 6px;
  letter-spacing: 0.28px;
}

.btn-outline-secondary {
  letter-spacing: 0.72px !important;
  line-height: 1.42 !important;
  font-weight: bold;
  font-size: $font-small;
  color: $imc-theme-color;
  border-color: $imc-theme-color;
  min-height: 40px;
  border-radius: 6px;
}

.btn-outline-secondary:hover {
  background-color: $imc-theme-color;
  border-color: $imc-theme-color;
}

.MuiOutlinedInput-notchedOutline {
  //border-color: rgba(102, 102, 102, 0.6);
}

.MuiOutlinedInput-root {
  border-radius: 4px;
  //margin-bottom: 10px;
}

.MuiOutlinedInput-root:hover {
  border-color: rgba(144, 163, 165, 0.6);
}

// .MuiOutlinedInput-root:hover .Mui-focused .MuiOutlinedInput-notchedOutline {
//   border-color: rgba(28, 117, 187, 0.87);
//   border-width: 1px;
// }
.MuiInputBase-input {
  height: 40px;
  line-height: 40px;
  padding-bottom: 0px;
  padding-right: 0px;
}

.MuiOutlinedInput-input {
  padding: 0 14px;
  font-size: 12px;
  color: #222222;
}

.MuiInputLabel-outlined {
  transform: translate(14px, 14px) scale(1);
  font-size: 11px;
  color: #727275;
}

.MuiSwitch-colorSecondary.Mui-checked {
  color: $imc-theme-color;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, 7px) scale(1);
}

.MuiFormLabel-root {
  font-size: $font-small;
  color: $imc-aubergine-color;
}

.MuiFormLabel-root.Mui-focused {
  font-size: $font-xssmall;
  color: $imc-subtitle-color;
}

.PrivateNotchedOutline-legendLabelled-3>span {
  padding-right: 1px;
}

.MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
  background-color: $imc-theme-color;
}

.MuiOutlinedInput-notchedOutline span {
  font-size: 11px;
  padding-right: 2px;
}

.MuiInputBase-input {
  height: 38px;
  line-height: 38px;
}

.MuiSelect-select.MuiSelect-select:hover {
  border-color: rgba(28, 117, 187, 0.87);
}

.headeBgWhite {
  background-color: $imc-white-color;
}

.bodyContentBg {
  background-color: $imc-white-color;
  padding: 20px;
  border-radius: 8px;
}

.container-fluid {
  // padding-left: 25px;
  // padding-right: 25px;
}

.navbar {
  padding: 0.5rem 25px;
}

.custonScrollBar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.custonScrollBar::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.2);
}

.customScrollBar::-webkit-scrollbar {
  background-color: transparent //$imc-white-color;
}

.customScrollBar::-webkit-scrollbar {
  width: 2px;
  height: 6px;
}

.customScrollBar::-webkit-scrollbar-corner {
  display: none;
}

.customScrollBar::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.2);
}

.custonScrollBar::-webkit-scrollbar {
  background-color: $imc-white-color;
}

@media (min-width: 576px) {
  .modal-sm {
    max-width: 368px;
  }

  .modal-lg {
    max-width: 882px;
  }
}

.alertModalSmall .modal-content {
  border-radius: 8px;
  box-shadow: 6px 6px 54px 0 rgba(0, 0, 0, 0.16);
  border: none !important;
  background: linear-gradient(to right, #203662 50%, #c50f3c 50%) top;
  background-repeat: no-repeat;
  background-size: 100% 3px;
  background-color: #fff;
}

.alertModalSmall.error .modal-content {
  border-radius: 8px;
  box-shadow: 6px 6px 54px 0 rgba(0, 0, 0, 0.16);
  border: none;
  border-top: solid 9px #f04134;
}

.modal {
  background: rgba(0, 0, 0, 0) !important;
  -webkit-backdrop-filter: blur(5.7px) !important;
  backdrop-filter: blur(5.7px) !important;
  //z-index: 9999;
}

.modal-content {
  border-radius: 8px;
  box-shadow: 6px 6px 54px 0 rgba(0, 0, 0, 0.16);
  border: none;
}

.modalTitle {
  font-size: 16px;
  font-weight: 600;
  color: #222222;
  border-bottom: solid 1px #e1e1e1;
  margin-left: -15px;
  margin-right: -15px;
  padding-bottom: 20px;
  padding-top: 5px;
  padding-left: 23px;
}

.dropdown {
  position: relative;
  z-index: 10;
}

.dropdown-menu {
  border-radius: 12px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  border: none;
  z-index: 20;

  li:hover {
    background-color: #f3f6fa;
  }
}

.dropdown-item {
  font-size: 12px;
  font-weight: 600;
  color: #1e1e1e;
}

.dropdown-item:active {
  color: #1e1e1e;
  text-decoration: none;
  background-color: #f0f0f0;
}

.MuiSelect-icon {
  color: $imc-theme-color;
  right: 7px;
}

.MuiSvgIcon-root {
  width: 1em;
  height: 1.5em;
  font-size: 1rem;
  color: $imc-theme-color;
  cursor: pointer;
}

.MuiIconButton-root:hover {
  background-color: transparent;
}

.MuiAutocomplete-endAdornment .MuiTouchRipple-root {
  display: none;
}

.logoCol img {
  margin-top: 16px;
}

.webviewer {
  // height: 100%;
  // width: 95%;
  height: 500px;
  flex: 1;
  margin: 8px;
  margin-right: 30px;
  margin-left: 0px;
  // -webkit-box-shadow: 1px 1px 10px #999;
  //         box-shadow: 1px 1px 10px #999;
}

.webviewer-height {
  min-height: 500px;
}

.MuiTypography-root {
  // font-family: "Open Sans", sans-serif !important;
}

.MuiPopover-paper .MuiMenu-list {
  max-height: 300px;
}

//Tabs
.MuiBox-root {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.MuiPaper-root {
  //box-shadow: none !important;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  margin-top: 4px;
  padding-top: 12px;
  padding-right: 2px;
  //padding-bottom: 12px;
}

.MuiAppBar-root {
  color: black !important;
  background-color: transparent !important;
}

.MuiTab-textColorInherit.Mui-selected {
  outline: none;
  font-weight: 600;
}

.MuiTabs-indicator {
  background-color: #1c75bb !important;
}

.MuiTab-root {
  text-transform: none !important;
  text-align: left;
  padding-left: 0px;
  padding-right: 0px;
  margin-right: 50px;
  font-size: 14px;
  line-height: 1.36;
  padding-top: 16px;
  padding-bottom: 16px;
}

.MuiTab-wrapper {
  width: auto;
}

.MuiTabs-root {
  margin-left: 20px;
}

@media (min-width: 600px) {
  .MuiTab-root {
    min-width: 80px;
  }
}

.btn-outline-primary {
  color: #3775b6;
  border-color: #3775b6;
  border-radius: 8px;
  border: solid 1px #3775b6;
  font-size: 12px;
  font-weight: bold;
  min-height: 40px;
}

.btn-outline-primary:hover,
.btn-outline-primary:active,
.btn-outline-primary:focus {
  color: #fff !important;
  background-color: #1c75bb !important;
  border-color: #1c75bb !important;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #1c75bb;
  background-color: transparent;
  opacity: 0.3;
}

.MuiSelect-select:focus {
  background-color: #fff !important;
}

.btn-cancel-rhs {
  margin-right: 30px;
}

.view-text-heading {
  font-weight: $font-wight-600;
  color: $imc-subtitle-color;
  width: 100%;
  text-align: left;
}

.shadow {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08) !important;
}

.modal-body {
  padding: 20px 0px 0px 0px;
}

.custom-scroll-bar::-webkit-scrollbar {
  background-color: $imc-white-color;
}

.custom-scroll-bar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.custom-scroll-bar::-webkit-scrollbar-corner {
  display: none;
}

.custom-scroll-bar::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.2);
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-width: 1px;
  border-color: $imc-border-color;
}

.MuiInputBase-root.Mui-disabled {
  background-color: $imc-disbaled-field-color !important;
  border: none !important;
  color: $imc-disabled-text-color !important;
}

.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
  border: none;
}

.MuiFormControl-root .MuiTextField-root {
  background-color: $imc-white-color;
}

.MuiFilledInput-root {
  background-color: $imc-white-color;
}

.cursor-pointer {
  cursor: pointer;
}

ul.appDropdown {
  margin-bottom: 0;

  :last-child {
    margin-bottom: 0 !important;
  }
}

.appDropdown a {
  font-size: 14px;
  font-weight: 700;
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 20px;

  &:hover {
    color: #203662;
    background-color: transparent;
  }

  img {
    margin-right: 8px;
    margin-top: -2px;
  }
}

.MuiSwitch-root {
  padding-bottom: 6px;
  padding-right: 0px;
  width: 40px;
  height: 26px;
  padding-top: 6px;
}

.MuiSwitch-thumb {
  width: 10px;
  height: 10px;
  margin-top: -1px;
  background-color: $imc-white-color;
}

.MuiSwitch-switchBase.Mui-checked {
  transform: translateX(18px);
}

.unchecked .MuiSwitch-switchBase {
  left: 5px;
}

.checked .MuiSwitch-switchBase {
  left: 0px;
}

.MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
  background-color: $imc-theme-color;
  opacity: 1;
}

.mt10 {
  margin-top: 10px;
}

.ml16 {
  margin-left: 16px;
}

.mr16 {
  margin-right: 16px;
}

.mr4 {
  margin-right: 4px;
}

.pl16 {
  padding-left: 16px;
}

.pr16 {
  padding-right: 16px;
}

.mr25 {
  margin-right: 25px;
}

.MuiListItem-root {
  background-color: transparent !important;
  font-size: $font-small;
  color: $imc-darkgrey-color;
}

.MuiListItem-root.Mui-selected {
  background-color: $imc-pale-gray-color !important;
}

.btnVhCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btnBottomCenter {
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
}

.MuiInputLabel-filled.MuiInputLabel-shrink {
  font-size: 10px !important;
  color: #66676b !important;
}

.MuiInputBase-root {
  font-family: "Nunito Sans", sans-serif !important;
}

.btnOutline {
  border: solid 1px #203662;
  border-radius: 16px;
  border: solid 1px #203662;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: #203662;

  img {
    width: 11px;
    margin-top: -4px;
  }

  &:hover {
    color: #203662 !important;
  }

  &:focus {
    box-shadow: none;
    color: #203662;
  }

  white-space: nowrap;
}

.MuiPaper-rounded {
  border-radius: 4px;
}

.font-14 {
  font-size: 14px;
}

.font-600 {
  font-weight: 600;
}

.mb-12 {
  margin-bottom: 12px;
}

.ml-12 {
  margin-left: 12px;
}

.heightNormal {
  height: auto !important;
  line-height: normal !important;
}

.ml-16 {
  margin-left: 12px;
}

.mr-16 {
  margin-right: 16px;
}

.ellipssesSingleLine {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.bold600 {
  font-weight: 600;
}

.mt-16 {
  margin-top: 16px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-20 {
  margin-top: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.pb20 {
  padding-bottom: 20px !important;
}

.pt15 {
  padding-top: 15px !important;
}

.mr12 {
  margin-right: 12px !important;
}

.pb15 {
  padding-bottom: 15px !important;
}

.mt2 {
  margin-top: -2px;
}

.mb6 {
  margin-bottom: 6px;
}

.pdlpdr15 {
  padding-left: 15px;
  padding-right: 15px;
}

.plr20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.plr15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.pl5 {
  padding-left: 5px !important;
}

.pr12 {
  padding-right: 12px !important;
}

.pl12 {
  padding-left: 12px !important;
}

.lh32 {
  line-height: 32px;
}

.customDropdownOutline {
  border: solid 1px #203662;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: #203662;
  padding: 8px 16px;
  cursor: pointer;

  img {
    width: 11px;
    margin-top: -3px;
  }

  &:hover {
    color: #203662 !important;
  }

  &:focus {
    box-shadow: none;
    color: #203662;
  }
}

.btn-link {
  color: #203662;

  &:hover {
    text-decoration: none;
    color: #203662;
  }

  &:focus {
    outline: 0;
    box-shadow: none;
    text-decoration: none;
  }

  &:disabled {
    cursor: default !important;
  }
}

.mui-popper {
  font-size: $font-small !important;
  overflow-x: hidden;
  height: 200px !important;
  padding-left: 0px;
  padding-top: 0px;
  margin-bottom: 0px !important;
}

input[type="checkbox"] {
  accent-color: $imc-theme-color;
}

.MuiAutocomplete-paper {
  padding: 0px !important;
}

.Toastify__toast-container {
  .Toastify__toast {
    padding: 20px 20px 12px 20px;
    border-radius: 12px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
    background: linear-gradient(to right, #203662 50%, #c50f3c 50%) top;
    background-repeat: no-repeat;
    background-size: 100% 3px;
    background-color: #f3f6fa;
    color: #000 !important;

    .Toastify__toast--success {
      background-color: #f3f6fa;
    }

    .Toastify__close-button svg {
      color: #1e1e1e;
    }
  }

  .Toastify__toast--error {
    color: #000 !important;
  }
}

// .MuiAutocomplete-root[aria-expanded="true"] .MuiChip-root {
//   display: none;
// }
// .MuiChip-root {
//   max-width: 200px;
// }
.Toastify__progress-bar {
  height: 0px !important;
}

.MuiChip-root {
  height: auto !important;
  padding: 4px !important;
  background-color: #f3f6fa !important;
  margin-left: 7px !important;
  padding-left: 2px !important;
  text-align: center;
  opacity: 1 !important;

  .MuiChip-label {
    padding-top: 0;
    //color: #1e1e1e !important;
  }
}

.multi-select-list::-webkit-scrollbar {
  width: 2px !important;
  height: 6px !important;
}

.multi-select-list::-webkit-scrollbar-corner {
  display: none;
}

.multi-select-list::-webkit-scrollbar-thumb {
  border-radius: 5px !important;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1) !important;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1) !important;
  background: rgba(0, 0, 0, 0.2) !important;
}

.MuiAutocomplete-option[data-focus="true"] {
  background-color: $imc-pale-gray-color;
}

.MuiAutocomplete-option[aria-selected="true"] {
  background-color: transparent !important;
}

.cardMenu {
  padding: 0;

  ul {
    margin-bottom: 0;
  }
}

.cardDropdown li {
  text-align: left;
  line-height: 32px;
  padding-left: 12px;

  &:hover {
    background-color: #f3f6fa;
  }

  &:last-child {
    margin-bottom: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:first-child {
    margin-bottom: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .btn {
    color: #1e1e1e;
    font-weight: 600 !important;
  }
}

.Mui-disabled .MuiAutocomplete-endAdornment {
  display: none;
}

// .no-arrow .tooltip-arrow {
//   display: none !important;
//   border-top-color: transparent !important;
// }
.dropdown .dropdown-menu ul {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

// .dropdown .dropdown-menu {
//   padding: 0 !important;
// }
.dropdown .dropdown-menu li:first-child {
  margin-bottom: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.dropdown .dropdown-menu li {
  text-align: left;
  line-height: 32px;
  padding-left: 12px;
  padding-right: 12px;
}

.dropdown .dropdown-menu li:hover {
  background-color: #f3f6fa;
}

.dropdown .dropdown-menu li:last-child {
  margin-bottom: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.dropdown .dropdown-menu li .btn {
  color: #1e1e1e;
  white-space: nowrap;
  font-weight: 600 !important;
}

.dropdown-item {
  padding: 0 12px !important;
  color: #1e1e1e;
  white-space: nowrap;
  font-weight: 600 !important;
  line-height: 32px;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: transparent !important;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #878888;
  pointer-events: none;
}

#tooltip {
  background-color: $imc-white-color;
  border: none;
  box-shadow: 0 3px 12px 0 rgba(35, 31, 32, 0.17);
  padding: 10px;
  font-size: $font-small;
  z-index: 999;
}

.scollable-tooltip {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 200px;
  padding-right: 10px;
  margin-right: -6px;
}

.popover>.arrow::before {
  top: 0;
  border-width: 0px;
  border-color: transparent;
}

.nav-item {
  position: relative;
  z-index: 10;
}

.userActionsDropdown {
  .btn-primary {
    min-width: none !important;
    line-height: normal;
  }

  &.show .btn-primary.dropdown-toggle {
    color: #203662 !important;
    border-color: #203662 !important;
  }

  &.show .btn-primary.dropdown-toggle:focus {
    box-shadow: none !important;
  }

  .btn-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none !important;
  }

  .btn-primary:not(:disabled):not(.disabled):active {
    color: #203662;
  }

  .btn-primary:focus {
    box-shadow: none !important;
  }

  .dropdown-menu {
    padding: 0 !important;
  }

  .dropdown-toggle {
    background-color: transparent;
    border: none;
    min-width: none !important;
    min-height: auto !important;
    padding: 0;
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: #203662;
    border: solid 1px #203662;
    border-radius: 16px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: #203662;
    padding: 8px 16px;
    background-color: transparent !important;
    min-height: auto;
    cursor: pointer;

    &::after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 1px;
      content: "";
      //background-image: url("./img/blue-down-arrow.svg.svg");
      background-image: url("./img/blue-down-arrow.svg");
      background-repeat: no-repeat;
      border-top: none !important;
      border-right: none !important;
      border-bottom: none !important;
      border-left: none !important;
      width: 11px;
      height: 6px;
      background-size: 11px;
    }
  }

  &:hover {
    color: #203662 !important;
  }

  &:focus {
    box-shadow: none;
    color: #203662;
  }

  &.show .btn-primary.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 1px;
    content: "";
    //background-image: url("./img/blue-down-arrow.svg.svg");
    background-image: url("./img/blue-up-arrow.svg.svg");
    background-repeat: no-repeat;
    border-top: none !important;
    border-right: none !important;
    border-bottom: none !important;
    border-left: none !important;
    width: 11px;
    height: 6px;
    background-size: 11px;
  }
}

button.userLogin {
  background-color: transparent !important;
  border: none !important;
  min-height: auto !important;
  cursor: pointer !important;
  min-width: auto !important;

  &::after {
    display: none;
  }
}

.MuiPaper-root {
  transition: none !important;
}

.three-dot-menu .dropdown-item {
  padding: 0px !important;
}

.d-inline-grid {
  display: inline-grid;
}

.MuiInputBase-input.Mui-disabled {
  color: $imc-disabled-text-color !important;
}

.MuiInputLabel-filled.MuiInputLabel-shrink {
  transform: translate(9px, 12px) scale(0.75) !important;
}

.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input {
  padding-top: 2px !important;
}

.Expired {
  font-size: 8px;
  font-weight: 600;
  text-align: center;
  color: #fff;
  background-color: #a9aaac;
  padding: 2px 4px;
  border-radius: 3px;
  margin-left: 6px;
}

.modal-datagraphic.modal-dialog {
  max-width: 700px !important;
}

.rt-id {
  display: inline-flex;
  align-items: center;
  color: #1e1e1e;
  font-weight: 600;
}

.MuiPaper-root {
  padding-top: 0 !important;
}

.mui-popper li:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.MuiAutocomplete-option[data-focus="true"] {
  background-color: #f3f6fa;
}

.textarea::-webkit-scrollbar {
  background-color: transparent //$imc-white-color;
}

textarea::-webkit-scrollbar {
  width: 2px;
  height: 6px;
}

textarea::-webkit-scrollbar-corner {
  display: none;
}

textarea::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.2);
}

.united-states-class {
  padding-top: 0px !important;
  margin-top: -10px;
  height: 150px;
}